<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Laporan</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-folder-open mg-r-5"></i><span class="breadcrumb-item active">Laporan</span></a>
            </div>
        </div> 

        <div class="webnots-information webnots-notification-box">Format laporan mengikuti <a style="color:yellow" target="_blank" :href="$apiconfig + 'dokumen/peraturan.pdf'" data-toggle="tooltip" data-placement="top" aria-describedby="jumlah" title="Klik untuk melihat peraturan" ><b>Permendagri 47 Tahun 2021</b></a> dan kebutuhan internal !</div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row row-xs">
            <div class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Laporan Persediaan</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format Internal )</p> 
                        <router-link :to="{ name:'laporan.persediaan' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Laporan Mutasi</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format Internal )</p> 
                        <router-link :to="{ name:'laporan.mutasi' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Stock Opname</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format Internal )</p> 
                        <router-link :to="{ name:'laporan.stock' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Buku Penerimaan Persediaan</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format II.I.3 )</p> 
                        <router-link :to="{ name:'laporan.buku_penerimaan' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Buku Pengeluaran Persediaan</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format II.I.4 )</p> 
                        <router-link :to="{ name:'laporan.buku_pengeluaran' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Kartu Barang Persediaan</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format II.I.5 )</p> 
                        <router-link :to="{ name:'laporan.kartu_barang' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-if="checkDomain != 'persediaan.e-planningbmd.id'" class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Buku Penyaluran Barang</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format II.I.10 )</p> 
                        <router-link :to="{ name:'laporan.buku_penyaluran' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">BA Perubahan Fisik</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format II.I.11 )</p> 
                        <router-link :to="{ name:'laporan.perubahan_fisik' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div> -->
            <div v-if="checkDomain != 'persediaan.e-planningbmd.id'" class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Laporan Persediaan Rusak Berat atau Usang</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format IV.I.1 )</p> 
                        <router-link :to="{ name:'laporan.rusak_usang' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <!-- <div v-if="userdata.role != 'auditor'" class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Laporan Pengadaan Aset Lancar menurut Sub Objek Rincian</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format IV.A.1.1.3 )</p> 
                        <router-link :to="{ name:'laporan.empty' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div> -->
            <!-- <div v-if="userdata.role != 'auditor'" class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Laporan Pengadaan Aset Lancar menurut Objek</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format IV.A.1.1.4 )</p> 
                        <router-link :to="{ name:'laporan.empty' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div> -->
            <!-- <div v-if="userdata.role != 'auditor'" class="col-sm-6 col-xl-3">
                <div class="card mg-b-20">
                    <div class="card-body text-center">
                        <img :src="'https://kdo.e-planningbmd.id/assets/images/laporan.svg'" alt="" class="img-fluid rounded-circle wd-60">
                        <h5 class="tx-dark tx-13 tx-medium mg-y-10 d-block">Laporan Pengadaan Aset Lancar menurut Objek</h5>
                        <p class="mr-3 tx-gray-500 tx-12"><i class="ion-ios-paper mr-2 text-info"></i>( Format IV.A.1.1.5 )</p> 
                        <router-link :to="{ name:'laporan.empty' }" >
                            <a href="" class="btn btn-sm btn-label-primary"><i class="ion-ios-eye mr-2 text-info"></i>Lihat</a>
                        </router-link>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Persediaan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                checkDomain: ''
            }
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.check_domain();
        },
        computed: mapState(['userdata']),
        components: {
        },
        methods: {
            check_domain() {
                // this.checkDomain = 'persediaanbmd.bandungkab.go.id';
                this.checkDomain = window.location.hostname;
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>